.expandable-input-wrapper {
    display: inline-block;
    height: 20px;
    position: relative;
}

.expandable-input-wrapper pre,
.expandable-input-wrapper input {
    height: 100%;
}

.expandable-input-wrapper input {
    width: calc(100% + 2px);
}

.expandable-input-wrapper pre {
    background: none;
    border: none;
    display: inline-block;
    font: inherit;
    padding: 0;
    opacity: 0;
    white-space: pre;
    z-index: -1;
}

.expandable-input {
    border: none;
    box-sizing: content-box;
    outline: 0;
    padding: 0;
    position: absolute;
    z-index: 1;
}

.expandable-input:focus {
    outline: 0;
}
