.dropdown-menu .footer {
    padding: 3px 20px;
    color: gray;
    font-style: italic;
}

.suggest-box .spinner {
    margin-top: -1px;
}

@keyframes spinner {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.suggest-box {
    display: inline-block;
    position: relative;
}

.suggest-box .dropdown {
    display: block;
    position: absolute;
    top: 100%;
}

.omnibox .suggest-box .dropdown {
    top: 100%;
    left: 0;
}

input::-ms-clear {
    display: none;
}

.suggest-box-dropdown button {
    display: block;
}
