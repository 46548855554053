.upload-container {
    border: 1px solid #ccc;
    border-radius: 4px;
    min-width: 200px;
    min-height: 100px;
    text-align: center;
}

.upload-container:hover {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}

.upload-container label {
    margin-bottom: 0;
    position: relative;
    width: 100%;
}

.upload-container .wrapper {
    padding: 40px 0;
}

.upload-container input {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
}

.upload-container .upload-button {
    position: relative;
    z-index: 100;
}

.upload-container .filename {
    font-weight: normal;
    margin-top: 5px;
}

.upload-container button {
    margin-bottom: 10px;
    margin-top: 5px;
}

.upload-container.dragged {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.upload-container .help-block {
    margin-bottom: 0;
}

.upload-container .help-block:empty {
    display: none;
}

.has-error .upload-container,
.has-error.upload-container {
    border-color: #a94442;
}

.form-group.required .upload-container label::after {
    content: none;
}
