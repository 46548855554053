.root {
    position: relative;
}

.root :global(.suggest-box) {
    display: block;
    width: 100%;
}

.root :global(.input-wrapper) {
    display: flex;
}

.input {
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    border-radius: 4px;
    font-size: 14px;
    padding: 6px 12px 2px;
    white-space: pre-line;
    width: 100%;
}

:global(.search-omnibox) .input {
    border: none;
    box-shadow: none;
}

.input:focus {
    border-color: #66afe9;
    border-top-color: rgb(102, 175, 233);
    border-right-color: rgb(102, 175, 233);
    border-bottom-color: rgb(102, 175, 233);
    border-left-color: rgb(102, 175, 233);
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}

.input::before {
    content: attr(data-placeholder);
    color: #979797;
    position: absolute;
    top: 7px;
}

.input :global(.dropdown) {
    user-select: none;
}

.input :global(.concept) {
    border: 1px solid darkgrey;
    font-size: 14px;
    margin-bottom: 3px;
    margin-top: -1px;
    margin-right: 0;
    cursor: pointer;
}

.input :global(span) {
    font-size: 14px !important;
    background-color: transparent !important;
}

.input :global(span:empty) {
    display: inline-block;
    min-width: 1px;
}

.input :global(.badge):empty {
    display: inline-block;
}

.input :global(span:empty:not(.spinner)),
.input > :global(span) {
    height: 16px;
    white-space: pre-wrap;
}

.input :global(br) {
    display: none;
}

.input :global(div) {
    display: inline-block;
}

@media (max-width: 479px) {
    :global(#search-concept-popover) {
        width: 100%;
    }
}

@media (min-width: 480px) {
    :global(#search-concept-popover) {
        width: 480px;
    }
}
