@media (max-width: 1199px) {
    .job-applicants-table-page #page-container {
        margin-left: 0px;
        margin-right: 10px;
        width: 100%;
    }
}

@media (min-width: 1430px) {
    .job-applicants-table-page #page-container {
        width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }
}
