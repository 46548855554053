.collapsible {
    max-height: 8.5em;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
}

.collapsible .toggle {
    top: 8.1em;
    cursor: pointer;
    display: block;
    position: absolute;
    text-align: center;
    font-size: 0.9em;
    width: 100%;
}

.collapsible::before {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 1) 100%);
    top: 6.5em;
    content: '';
    display: block;
    height: 2em;
    position: absolute;
    width: 100%;
}

.collapsible.expanded {
    max-height: none;
    padding-bottom: 20px;
}

.collapsible.expanded::before {
    content: none;
}

.collapsible.expanded .toggle {
    bottom: 0;
    top: auto;
}
