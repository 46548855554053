.concept {
    background-color: #e9e9e9;
    border: none;
    color: black;
    margin-bottom: .6em;
    margin-right: .4em;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 500;
}

.concept:focus {
    outline: none;
}

.concept-pointer {
    cursor: pointer;
}

.concept.has-tf {
    background-color: #A5D6D9;
}

.basic-concept {
    color: grey;
}

.original-concept::after {
    content: '\2605';
    font-size: 150%;
    margin-left: 1px;
    line-height: 0;
}

.concept[aria-describedby] {
    color: #23527c;
    background-color: #ddd;
}

.concept-basic-popover h3 a,
.concept-popover h3 a {
    float: right;
    margin-left: 20px;
    text-transform: lowercase;
}

.expand-concepts-link {
    color: grey;
    cursor: pointer;
}

.static-more {
    color: grey;
}

.concept-list-grouped {
    margin-bottom: 0.5em;
}

.expand-text-link {
    color: grey;
    cursor: pointer;
    background-color: #e9e9e9;
    font-weight: 500;
}

.highlighted-concept {
    color: green;
}

.concept sup {
    padding-left: 0.3em;
}

.concept-popover {
    max-width: 50%;
}

.concept-brief-popover {
    width: 40em;
}

.concept-basic-popover {
    max-width: 50%;
    width: 30em;
}

@media (max-width: 767px) {
    .concept-basic-popover {
        max-width: 90%;
        width: 30em;
    }
}

.concept-basic-popover h3 span {
    display: inline-block;
    max-width: calc(100% - 150px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.concept-basic-popover .loading-wrapper {
    height: auto;
    padding: 0;
}

@media (max-width: 767px) {
    .concept-popover {
        max-width: 100%;
    }
}

.highlighted-concept,
.concept-chosen {
    color: black;
    cursor: pointer;
    border: 1px solid darkgrey;
}

.button-remove-concept {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.unconfirmed-concept {
    color: #337ab7;
}

.concept-popover-button-group {
    width: 100%;
}

.special-button {
    width: 50%;
    background-color: #f7f7f7;
    border-bottom: 1px solid #f7f7f7;
    border-left: 1px solid #f7f7f7;
    border-top: 1px solid #f7f7f7;
    color: #337ab7;
}

.topic-color {
    border-radius: 5px;
    border: 5px solid;
    display: inline-block;
    font-size: 0;
    margin-top: -1px;
    margin-right: 3px;
    vertical-align: middle;
}

.topic-color-0 {
    border-color: #1f77b4;
}

.topic-color-1 {
    border-color: #aec7e8;
}

.topic-color-2 {
    border-color: #ffbb78;
}

.topic-color-3 {
    border-color: #2ca02c;
}

.topic-color-4 {
    border-color: #98df8a;
}

.topic-color-5 {
    border-color: #d62728;
}

.topic-color-6 {
    border-color: #ff9896;
}

.topic-color-7 {
    border-color: #9467bd;
}

.topic-color-8 {
    border-color: #9467bd;
}

.topic-color-9 {
    border-color: #8c564b;
}

.topic-color-10 {
    border-color: #c49c94;
}

.sticky-button {
    background-color: #f7f7f7;
    border: 1px solid #ebebeb;
    border-radius: 10px 0 0 10px;
    color: #337ab7;
    cursor: pointer;
    overflow-x: hidden;
    padding: 6px;
    position: fixed;
    right: 0;
}

@media (max-width: 480px) {
    .sticky-button {
        bottom: 0;
    }

    .sticky-button .horizontal-line {
        display: none;
    }
}

@media (min-width: 481px) {
    .sticky-button {
        height: 12em;
        margin-top: -6em;
        top: 50%;
    }
}

.doc-topic td:first-child {
    height: 100%;
}

.doc-topic td {
    padding-right: 1em;
}

.doc-topic td:last-child {
    padding: 6px 0;
}

.doc-topic ul {
    list-style: none;
}

.topic-panel {
    margin-bottom: 1em;
}

#add-to-topic-select {
    margin-left: 10px;
    min-width: 150px;
}

label[for="add-to-topic-select"] {
    min-width: 110px;
    line-height: 34px;
}

#concept-suggestions-popover {
    min-width: 312px;
}

#concept-suggestions-popover .dropdown {
    vertical-align: top;
}

#concept-suggestions-popover .popover-footer {
    padding: 8px 14px;
}

#concept-suggestions-popover .popover-footer button:first-child {
    margin: 0;
}

#concept-suggestions-popover .glyphicon-ok {
    margin-left: 5px;
    color: #5cb85c;
    opacity: 1;
    transition: opacity .5s;
    transition-delay: .2s;
}

#concept-suggestions-popover .glyphicon-ok.disappeared {
    opacity: 0;
    transition: opacity .1s;
}

.toggle-top-concepts {
    font-size: 14px;
    font-weight: normal;
    margin-left: 7px;
}

.top-concepts {
    margin-top: 10px;
}
