.entity-meta dt {
    width: 100px;
    padding-top: 5px;
}

.entity-meta dd {
    padding-top: 5px;
    margin-left: 120px;
}

.entity-meta-wrapper {
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-top: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.entity-meta ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}

.entity-meta li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.entity-meta .authors {
    margin: 5px 0;
}

.notable-icon {
    color: #FF7E26;
}
