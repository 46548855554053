.top-concepts-chart tr {
    font-size: 12px;
    line-height: 16px;
}

.top-concepts-chart tr:hover {
    background-color: rgb(240, 240, 240);
}

.top-concepts-chart tr td:first-child {
    padding-right: 10px;
    text-align: right;
    width: 40%;
}

.top-concepts-chart td {
    padding: 1px;
    vertical-align: middle;
}

.top-concepts-chart td a {
    color: rgb(51, 51, 51);
}

.top-concepts-chart .bar {
    border: 1px solid;
    height: 16px;
}

.top-concepts-chart .text-muted {
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 10px;
}

.top-concepts-chart .selected {
    font-weight: bold;
}

.top-concepts-chart .has-selected tr {
    opacity: 0.6;
}

.top-concepts-chart .has-selected .selected,
.top-concepts-chart .has-selected tr:last-child,
.top-concepts-chart .has-selected tr:hover {
    opacity: 1;
}
