.panel-member-candidates-table .score,
.panel-member-candidates-table .h-index,
.panel-member-candidates-table .articles-count,
.panel-member-candidates-table .score-by-subpanels,
.panel-member-candidates-table .citations-count,
.panel-member-candidates-table .actions {
    width: 1%;
    white-space: nowrap;
}
.panel-member-candidates-table .panel-member-candidate-icons-cell .referee-icons {
    float: right;
    margin-left: 6px;
}

.panel-member-candidates-table .actions .btn {
    margin-right: 5px;
}

.panel-member-candidates + .load-more {
    margin-bottom: 20px;
}

.panel-member-candidates-table tr.expanded + tr:last-child td,
.panel-member-candidates-table tr:not(.expanded):nth-last-child(2) td {
    border-bottom: 2px solid #ddd;
}

.panel-member-custom td {
    background-color: #daeaf3;
}
