.year-limit-filter .search-entity {
    width: 600px;
}

.year-limit-filter .search-entity input.form-control {
    width: 100%;
}

.search-block {
    margin: 10px 0 30px;
    padding: 0;
}

.selected-articles {
    padding: 4px 3px 0 3px;
    margin: 10px 0 0;
}

.search-header {
    padding: 5px 5px 0 5px;
    margin-bottom: 10px;
}

.search-header .search-help-container {
    padding-bottom: 0;
    padding-left: 4px;
}

.search-entity {
    padding: 0;
}

.search-entity.results {
    margin-top: 0;
    margin-bottom: 0;
}

.search-entity.results > ul {
    list-style: none;
    margin-top: 5px;
    overflow: auto;
    padding: 0;
}

.search-entity.results .info {
    margin-left: 10px;
    font-weight: bold;
}

.search-entity.results > ul > li:first-child {
    margin-top: 0;
}

.search-entity.results > ul > li,
.search-entity.added > ul > li {
    border-bottom: 1px solid #ddd;
    overflow: hidden;
    padding: 10px 10px 5px 10px;
}

.search-entity.results > ul > li:last-child,
.search-entity.added > ul > li:last-child {
    border-bottom: none;
}

.search-entity.results > ul > li.active {
    background-color: #f9f9f9;
}

.search-entity.results > ul > li:hover {
    background-color: #fbfbfb;
}

.article-suggest.with-action .article-card {
    float: right;
    width: calc(100% - 78px);
}

.article-suggest .action {
    float: left;
    text-align: center;
}

.article-suggest .btn {
    width: 70px;
    text-align: center;
    margin-left: -2px;
}

.article-suggest .btn-danger {
    background: transparent;
    color: #d9534f;
}
.article-suggest .btn-success {
    background: transparent;
    color: #5cb85c;
}

.search-entity .details {
    font-size: 12px;
}

.search-entity .details > ul,
.search-entity .added > ul {
    list-style: none;
    padding: 0;
}


.search-entity.results > div:first-child,
.search-entity.added > div:first-child {
    font-weight: bold;
}

.search-entity .brief a + span {
    margin-left: 10px;
}

.search-entity .brief a + span::before {
    color: #ccc;
    content: '|';
    margin-right: 10px;
    display: inline;
}

.search-authors-block {
    border: 0;
}

.search-authors-block .results {
    margin-top: -15px;
}

.search-authors-block .info {
    margin-top: 25px;
    margin-bottom: 0px;
}

.search-authors-block .action .btn {
    padding: 2px;
    margin-top: -3px;
}

.search-authors-block .top-clusters-pills li strong {
    padding: 4px 0;
    display: inline-block;
}
.search-authors-block .top-clusters-pills li a {
    padding: 5px 7px;
}

.search-authors-block .search-entity .suggest-box {
    width: 100%;
}

.search-entity .suggest-box-dropdown {
    display: none;
}

.article-card .article-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 2px;
}

.article-card .article-authors {
    margin-bottom: 2px;
    color: #000;
}

.edit-job-form .search-entity .suggest-box-dropdown {
    display: block;
}

.search-entity .or {
    margin: 0 10px;
    line-height: 34px;
}
