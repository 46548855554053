.monitoring-topics .panel {
    margin: 10px 0;
}

.monitoring-topics .topics-list {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}

.monitoring-topics .topics-list li {
    cursor: pointer;
    padding: 3px 10px;
}

.monitoring-topics .topics-list li:hover,
.monitoring-topics .topics-list li.current {
    background-color: #f5f5f5;
}

.monitoring-topics .topics-list li .highlight {
    background: #ddd;
}
