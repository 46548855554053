.edit-author-article label {
    font-weight: inherit;
    display: inline;
}

.edit-author-article input {
    float: left;
    margin: 0.5em 0 0 -1em;
}

.pinned-article-glyph {
    float: left;
    margin: 2.5em 0 0 -1em;
}

.edit-author-article-selected {
    background-color: lightyellow;
}

.edit-button-container .btn.btn-default {
    text-align: left;
}

.facets .filter-author,
li .filter-author {
    visibility: hidden;
}

.facets *:hover > .filter-author, .facets .filter-author.active,
li:hover > .filter-author, li > .filter-author.active {
    visibility: visible;
}

ul.affiliations li {
    color: #8a8a8a;
}

ul.affiliations li.includes-id {
    color: #333;
}

.author h2 {
    margin-bottom: 25px;
}

.author h4 {
    margin-top: 20px;
}

.author h4+h4 {
    margin-top: 0;
}

.author h2 small {
    color: #333;
    float: right;
    line-height: 36px;
}

.affiliations.edit {
    padding-left: 20px;
    list-style-type: none;
}

.affiliations.edit .toggle {
    margin-left: 20px;
}

.affiliations.edit input[type="checkbox"] {
    margin-right: 8px;
}

.author .sort-by {
    margin: 20px 15px 0 0;
}

.author .current-user-link {
    display: inline;
}

.claimed-info {
    text-align: center;
}
