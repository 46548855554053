.loading-wrapper {
    height: 100px;
    padding: 12px;
}

.loading {
  display: inline-block;
  overflow: hidden;
  height: 1.3em;
  line-height: 1.5em;
  vertical-align: text-bottom;
}

.loading::after {
  display: inline-table;
  white-space: pre;
  text-align: left;
}

.loading::after {
  content: "\A.\A..\A...";
  animation: spin4 2s steps(4) infinite;
}

@keyframes spin4 {
  to {
    transform: translateY(-6.0em);
  }
}

.with-spinner {
  min-width: 85px;
}
