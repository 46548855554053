span.text-with-tooltip {
    cursor: pointer;
    position: relative;
    text-decoration: underline dotted #999;
}

span.text-with-tooltip::after {
    bottom: 3px;
    content: '?';
    font-size: 12px;
    position: absolute;
}
