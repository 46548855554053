.ontology-moderation-log .text-danger::before {
    content: '-\00a0';
}

.ontology-moderation-log .text-success::before {
    content: '+\00a0';
}

.filters-and-pagination {
    margin-top: 20px;
}

.filters-and-pagination .pagination,
.filters-and-pagination span:first-child {
    margin-top: 0;
}

.filters-and-pagination .form-group {
    margin-right: 5px;
}

.ontology-moderation-log .date {
    width: 135px;
}

.ontology-moderation-log .name {
    width: 200px;
}

.ontology-moderation-log .basic {
    width: 65px;
}

.ontology-moderation-log .categories {
    width: 200px;
}

.ontology-moderation-log .email {
    width: 215px;
}
