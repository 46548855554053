.edit-job-form {
    margin-top: 50px;
}

.edit-job-form .help-block {
    padding-top: 3px;
}

.job .info .help-block,
.edit-job-form .info .help-block {
    display: inline-block;
    font-size: 14px;
    margin-left: 5px;
    font-weight: normal;
}

.edit-job-form .checkbox .help-block {
    display: inline;
    margin-left: 5px;
}

.edit-job-form .form-group label {
    display: block;
}

.edit-job-form textarea + .form-group {
    margin-top: 10px;
}

.edit-job-form #job-articles {
    min-height: 200px;
}

.edit-job-form .required label::after {
    margin-right: -9px;
}

.edit-job-form .top {
    margin-bottom: 7px;
}

.edit-job-form .search-omnibox {
    flex-grow: 1;
    margin: 0;
}

.edit-job-form .search-block .col-right {
    padding-left: 1px;
    padding-top: 1px;
}
.edit-job-form .search-block .col-left {
    padding-right: 1px;
}

.edit-job-form .search-entity.results > ul {
    margin-bottom: 0;
}


.job-form .upload-container {
    width: 100%;
}

.job .description,
.job-form .description {
    margin: 0 0 20px 0;
}

.job-form .toggle + .alert {
    margin-top: 10px;
}

.edit-job-form .job-articles-results h4 button {
    font-size: 14px;
    font-weight: normal;
}

.job-applicants {
    margin-top: 7px;
}

.job-applicants-table .rank,
.job-applicants-table .similarity-combined,
.job-applicants-table .similarity-max,
.job-applicants-table .articles-count,
.job-applicants-table th.notable-articles-count,
.job-applicants-table .academic-age,
.job-applicants-table .h-index,
.job-applicants-table .citations {
    white-space: nowrap;
    width: 5%;
    text-align: right;
}

.job-applicants-table .id {
    padding-left: 3px;
    padding-right: 1px;
}

.job-applicants-table .name {
    line-height: 20px;
}

.job-applicants-table th.name {
    line-height: 1.42857143;
    z-index: 4;
}

.job-applicants-table .name button {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15vw;
}

.job-applicants-table.filters-enabled .filters {
    max-height: none;
}

.job-applicants-table .reference {
    width: 15%;
}

.job-applicants-table .attachments {
    width: 145px;
}

.job-applicants-table ul {
    list-style: none;
    padding: 0;
}

.job,
.job-applicants,
.edit-job-form,
.jobs-dashboard,
.job-form {
    margin-bottom: 40px;
}

.job-form h4 + .help-block {
    margin-bottom: 0;
}

.job-applicant {
    margin-bottom: 40px;
}

.job-applicant .preliminary-badge {
    font-size: 0.8em;
    padding-left: 10px;
}

.job-applicant-sidebar {
    padding-top: 10px;
}

.job-applicant-sidebar > div {
    margin-bottom: 20px;
}

.job-applicant-sidebar h4 {
    margin-top: 0;
    display: inline-block;
}

.applicant-email {
    overflow: hidden;
}

.applicant-email h4 {
    text-overflow: ellipsis;
}

#applicant-popover .popover-content .votes,
#applicant-popover .popover-content > .comment {
    align-items: baseline;
    display: flex;
}

.job-applicant .text-with-tooltip {
    font-size: 14px;
}

.job-applicant .entity-meta .origins {
    display: none;
}

.toggle-abstracts {
    text-align: right;
    margin-top: -40px;
}
.toggle-abstracts label {
    font-weight: normal;
}

.applicant-articles.hidden-abstracts .abstract {
    display: none;
}

#job-description {
    min-height: 200px;
}

.markdown-help-container {
    max-height: 500px;
    overflow: auto;
}

#markdown-help-popover {
    max-width: none;
}

@media (min-width: 768px) {
    #markdown-help-popover {
        min-width: 600px;
    }
}

#markdown-help-popover h1,
#markdown-help-popover h2,
#markdown-help-popover h3,
#markdown-help-popover ul,
#markdown-help-popover ol,
#markdown-help-popover blockquote {
    margin: 0;
}

#markdown-help-popover blockquote {
    padding: 5px 20px;
}

#markdown-help-popover hr {
    margin: 10px 0;
}

.job-recommendation-form {
    margin-bottom: 40px;
}

.job-recommendation-form h4 + .current-user-link {
    margin-top: 20px;
}

.author-brief h3 {
    margin-top: 0;
}

.in-future {
    font-style: italic;
}

.application-attachments {
    padding-left: 20px;
}

.application-attachments.list-type-inline li {
    display: inline-block;
}

.application-attachments.list-type-inline li:not(:last-child)::after {
    content: ' |\00a0';
}

.form-advisors-list li {
    padding-left: 0px;
    padding-right: 10px;
}
.form-advisors-list .label-required {
    padding-left: 25px;
}

#applicant-popover .application-attachments {
    display: inline-block;
    margin-bottom: 0;
    padding-left: 0;
}

#applicant-popover {
    z-index: 1030;
}

@media (min-width: 768px) {
    #applicant-popover,
    #advisor-popover {
        min-width: 550px;
    }
}

#applicant-popover h3 a,
#advisor-popover h3 a {
    float: right;
}

.job-applicants-table .sortable-cell {
    padding-right: 8px !important;
}

.job-applicants-table .selectable-cell {
    padding-left: 8px !important;
}

.job-applicants-table .name .social-labels {
    display: inline-block;
}

.social-labels > span {
    cursor: help;
    margin-right: 2px;
}

.social-label + .social-labels {
    background-color: red;
}

.job-applicants-table .applicants-list {
    margin-bottom: 0;
}

.job-applicants-table .id {
    text-align: right;
    padding-top: 8px;
}

.job-applicants-table .name {
    padding-top: 8px;
}

.job-applicants-table .name button {
    margin-bottom: 0;
    line-height: 16px;
}

.job-applicants-table .reference button {
    line-height: 16px;
    margin-bottom: 6px;
}

.without-recommendation button {
    color: #a94442;
}

.votes-detalization .tooltip-inner {
    max-width: 500px;
    text-align: left;
}

.job-applicants-table .votes .dropdown {
    margin-top: -5px;
}

.job-applicants-table .votes .text-with-tooltip {
    vertical-align: top;
}

@media (min-width: 768px) {
    .job-applicants-table.applicants-tab th:last-child {
        width: 200px;
    }
}

.recommenders-tab .sortable-cell .order {
    right: 3px;
}

.vote-button {
    float: right;
}

.vote-button .vote-button-container {
    float:right;
    text-align: left;
    white-space: nowrap;
}

.vote-button .text-with-tooltip {
    display: inline-block;
    height: 16px;
    min-width: 35px;
    text-align: right;
}

.vote-button .glyphicon {
    padding: 2px;
    color: gray;
    font-size: 120%;
    cursor: pointer;
    vertical-align: bottom;
    flex-grow: 1;
}

.vote-button .glyphicon:hover {
    color: #333;
}

.vote-button .glyphicon.voted {
    text-shadow: #333  1px 1px 2px, #333 -1px -1px 2px,
                 #333 -1px 1px 2px, #333  1px -1px 2px;
}

.vote-button .glyphicon-star.voted,
.vote-button .glyphicon-star-empty.voted {
    color: gold;
}

.vote-button .glyphicon-ok.voted {
    color: #0d0;
}

.vote-button .glyphicon-ban-circle.voted {
    color: red;
}

.vote-button button {
    white-space: nowrap;
}

.rank.text-with-tooltip {
    white-space: nowrap;
}

.btn-toolbar .or {
    float: left;
    margin-top: 7px;
    margin-left: 5px;
}

#applicant-popover .col-md-5 {
    margin-top: 5px;
}

#applicant-popover .social-container {
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 5px 10px;
}

#applicant-popover .vote-button .text-with-tooltip {
    text-align: left;
}

#applicant-popover .social-container strong {
    width: 75px;
}

.preliminary-badge {
    display: inline-block;
    user-select: none;
}

#applicant-popover .applicant-tags {
    display: inline-block;
    vertical-align: middle;
}


.jobs-dashboard .current-user-link:first-child button {
    text-transform: capitalize;
}

.jobs-dashboard .entity {
    margin-top: 24px;
}

.jobs-dashboard .filters {
    overflow: hidden;
    margin-bottom: 10px;
}

.jobs-dashboard .filters .checkbox {
    margin: 0;
}

.jobs-dashboard .entity-meta-wrapper {
    margin-top: -9px;
}

.jobs-dashboard .entity > div:first-child {
    margin-top: 11px;
}

.job-applicants-table .applicant-tags {
    display: inline-block;
    float: none !important;
    vertical-align: middle;
    margin-top: 3px;
    margin-left: -2px;
}
.job .entity-meta .private {
    margin-top: 10px;
}

.applicant-tags {
    line-height: 20px;
    margin-right: -5px;
}

.label-applicant-tag {
    background: #e9e9e9;
    border: 1px solid #dadada;
    border-radius: .25em;
    color: #555;
    cursor: pointer;
    display: inline-block;
    font-size: 75%;
    font-weight: 700;
    line-height: normal;
    margin-right: 5px;
    padding: 0;
    text-align: center;
    white-space: nowrap;
    height: 19px;
}

.label-applicant-tag > span:first-child {
    display: inline-block;
    padding: .2em .4em .3em .6em;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.label-applicant-tag > span + span:last-child {
    border-left: 1px solid #d3d3d3;
    display: inline-block;
    padding: .2em .5em .3em .5em;
    vertical-align: top;
}

.label-applicant-tag .glyphicon {
    font-size: 9px;
    line-height: 10.5px;
}

.label-applicant-tag-add {
    background: white;
    user-select: none;
}

.label-applicant-tag > span:hover {
    background: #dfdfdf;
}

.label-applicant-tag-add:hover {
    background: #ededed;
}

.job-applicant-sidebar .applicant-tags .label-applicant-tag-add:first-child {
    vertical-align: text-bottom;
    float: right;
}

.job-applicants .applicant-comments .inline-link {
    font-size: 85%;
    margin: 5px 3px 5px auto;
    display: block;
}

.applicant-comments ul {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
}

.applicant-comments ul li {
    margin-left: 0;
    padding-left: 0;
}

.applicant-comment {
    padding: 1px;
}

.job-applicants-table .applicant-comment-button,
#applicant-popover .applicant-comment-button {
    font-size: 11px;
}

.job-applicants-table .applicant-comment {
    width: calc(100% + 200px);
    margin-left: -200px;
    margin-bottom: 15px;
    font-size: 0.8em;
    line-height: 1em;
}

#applicant-popover .applicant-comment {
    font-size: 0.8em;
    line-height: 1em;
}

.applicant-comment .collapsible::before {
    background-image: linear-gradient(to bottom, #eee0 0%, #eeeeeeb3 30%,
                                      #eeeeeed9 50%, #eee 100%);
}
.applicant-comment .collapsible {
    /*margin-bottom: 0;*/
}

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -1px;
    bottom: -20px;
    border: 12px solid;
    border-color: #666 #666 transparent transparent;
}
.tri-right.btm-right:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 0px;
    bottom: -20px;
    border: 12px solid;
    border-color: #eee #eee transparent transparent;
}

.tri-right.btm-left:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 0px;
    right: auto;
    top: auto;
    bottom: -20px;
    border: 20px solid;
    border-color: transparent transparent transparent #eee;
}

.job-applicants-table .tri-right:after,
#applicant-popover .tri-right:after {
    bottom: -10px;
    border-width: 10px;
}

.talk-bubble {
    margin: 5px 0 40px;
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background-color: #eee;
}


/* talk bubble contents */
.talktext {
    padding: 0.5em;
    text-align: left;
    margin-bottom: -15px;
    /*line-height: 1.5em;*/
}

.job-applicants-table .talktext {
    margin-bottom: -10px;
}

.talktext p{
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

.border{
  border: 1px solid #666;
}
.round{
  border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
}

.comment-author {
    position: relative;
    font-size: 11px;
    left: 15px;
    bottom: -22px;
    margin-right: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    z-index:10;
}



.comment-author .comment-counter {
    float: right;
    padding: 0 3px;
    color: #999;
}

.comment-author .comment-counter:hover {
    color: #000;
    text-decoration: none;
}

.comment-author .glyphicon {
    font-size: 9px;
}

.job-applicants-table .comment-author,
#applicant-popover .comment-author {
    left: 5px;
    bottom: -14px;
    margin-right: 5px;
}

.own-comment .comment-author {
    right: 15px;
    left: inherit;
    margin-right: 0;
    margin-left: 15px;
    text-align: right;
}

.job-applicants .settings {
    display: none;
}

.job-applicants .settings .alert {
    z-index: 6;
}

.job-applicants .panel-title .inline-link.active {
    color: inherit;
}

.job-applicants.show-settings .settings {
    display: block;
}

.table-jobs-topics-settings .table-row {
    position: relative;
}

.table-jobs-topics-settings .tag {
    width: 40%;
}

.table-jobs-topics-settings .tag,
.table-jobs-topics-settings .tag-applicants {
    display: table-cell;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.job-applicants.show-settings .sortable-table {
    position: relative;
}

.job-applicants.show-settings .toggle-filter {
    display: none;
}

.job-applicants.show-settings .sortable-table::before {
    background-color: black;
    content: '';
    display: block;
    height: 100%;
    opacity: 0.3;
    position: absolute;
    width: 100%;
    z-index: 5;
}

.job-applicants .settings .btn-toolbar {
    margin-top: 15px;
}

.applicant-draggable {
    display: inline-block;
    white-space: nowrap;
}

.table-jobs-topics-settings .unclassified {
    display: block;
}

.table-jobs-topics-settings .tag-applicants .applicant-draggable > span,
.unclassified .applicant-draggable > span {
    margin-right: 10px;
}

.job-applicants .panel-title .inline-link span:first-child {
    text-transform: lowercase;
    vertical-align: text-top;
}

.just-added {
    font-weight: bold;
}

@keyframes pulse {
    from { opacity: 1; }
    50% { opacity: 0; }
    to { opacity: 1; }
}

.already-exists {
    animation: 1s linear .2s infinite alternate pulse;
}

.applicant-draggable + div {
    display: inline-block;
    height: auto !important;
}

.search-articles button {
    margin-top: 4px;
}

.actual-badge {
    background-color: #337ab7;
    border-color: #337ab7;
}

.preliminary-badge-error-text {
    display: block;
    text-align: left;
}

.add-preliminary-button {
    min-width: 230px;
}

.add-preliminary-button .loading {
    display: none;
}

.show-preliminaries {
    margin-left: 8px;
    font-weight: normal;
}

.show-preliminaries input {
    vertical-align: text-bottom;
}

th.academic-age {
    white-space: nowrap;
}

.job-applicants-search .suggest-box {
    width: 100%;
}

.job-applicants-search .mass-actions {
    margin-top: 20px;
}

.job-applicants-search .most-relevant-articles > div:first-child {
    display: none;
}

.most-relevant-articles ul {
    padding-left: 0;
}

.most-relevant-articles li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.has-uploaded-recommendation {
    padding-top: 6px;
    color: green;
}

.waiting-for-recommendation {
    padding-top: 6px;
}

.application-form .help-block {
    margin-top: 7px;
}

.application-form .upload-parent-container {
    padding-top: 7px;
}

.job-email-btn {
    padding-right: 5px;
}

.job-navbar-btn-disabled {
    padding-left: 5px;
    padding-right: 5px;
    cursor: not-allowed;
}

.job-email-text-greeting {
    border-radius: 4px 4px 0px 0px;
    border-width: 1px 1px 0px 1px;
}

.job-email-text {
    min-height: 200px;
    border-radius: 0px 0px 4px 4px;
}

.job-email-error {
    margin-top: 1em;
}

.job-email-recipients-list {
    border: 1px solid #cccccc;
    border-radius: 4px;
    max-height: 100px;
    overflow: auto;
    margin-bottom: 15px;
}

.job-email-recipients-list>.job-email-recipient:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.job-email-recipient {
    padding-left: 0.5em;
}

.job-email-votes {
    margin-bottom: 0.25em;
}

.job-email-prelim-count {
    margin-left: 0.25em;
}

.job-email-emailed-count {
    margin-left: 0.25em;
}

.job-email-vote {
    margin-right: 1em;
}

.job-email-vote .glyphicon {
    cursor: default;
}

.job-or {
    margin-left: 1em;
    margin-right: 1em;
}

.job .alert:first-child {
    margin-top: 20px;
}

.job .advertisement {
    margin-top: unset;
}
