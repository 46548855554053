.table-filterable th:last-child {
    position: relative;
}

.table .toggle-filter {
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: -8px;
    top: 8px;
}

.table .toggle-filter-inner {
    background-color: #888;
    padding: 5px 13px;
    position: relative;
}

.table .toggle-filter-inner:hover {
    background-color: #555;
}

.table.filters-enabled .toggle-filter-inner {
    background-color: #333;
}

.table.table-filterable th {
    vertical-align: top;
}

.table th.sortable-cell {
    padding-right: 10px;
}

.table.table-filterable th:last-child {
    padding-right: 37px;
}

.job-applicants-table th {
    padding: 15px 5px 3px 7px !important;
}

.sortable-cell {
    position: relative;
    cursor: pointer;
}

.sortable-cell .th-label {
    position: relative;
    display: inline-block;
    white-space: nowrap;
}

.sortable-cell .th-label .order {
    position: absolute;
    opacity: 0.3;
    margin-top: -1.8em;
    right: 0px !important;
}

.sortable-cell:hover .order {
    opacity: 0.7;
}

.sortable-cell.sortable-cell-sorted .order {
    opacity: 1;
}

.sortable-cell.sortable-cell-asc .order > .dropdown,
.sortable-cell.sortable-cell-desc .order > .dropup {
    display: none;
}

.table-filterable .toggle-filter-inner::after {
    border-top: 8px solid #bbb;
    border-right: 8px solid white;
    bottom: -8px;
    content: '';
    display: block;
    height: 8px;
    position: absolute;
    right: 0;
    width: 8px;
}

.filterable-between .filters input {
    width: calc(50% - 11px);
}

.filterable-substring .filters input {
    width: 100%;
}

.table-filterable .filter-indicator {
    color: #779f77;
    margin-left: 2px;
    pointer-events: none;
    position: absolute;
    top: 7px;
    transform: scaleX(0.8) scaleY(0.8);
}

.sortable-table .sortable-cell .order {
    top: 1em;
    right: auto;
}

.filters label {
    font-weight: normal;
}

.table-filterable .filters {
    font-weight: normal;
    margin-top: 5px;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: .2s ease-out;
    transition-delay: 0s, .2s;
    transition-property: max-height, opacity;
}

.filters-enabled .filters {
    max-height: 30px;
    opacity: 1;
    overflow: visible;
}


/*************************************************/
/*                ANIMATIONS                     */
/*************************************************/

/* General animation */
.table-animated .cell-wrapper,
.table-animated tr {
    transition: .1s linear;
}

.table-animated .cell-wrapper {
    transition-delay: .2s, .2s;
}

/*
We remove td padding and move it to .cell-wrapper
in order to avoid manipulating too many selectors
for animation
*/
.table-animated tbody>tr>td {
    padding: 0;
}

.table-animated .cell-wrapper {
    padding: 8px;
}


/* Fade out */

/* Step 1: disappear */
.table-animated tr {
    opacity: 1;
    transition: opacity .1s ease-in, transform .1s linear;
}

.table-animated tr.fade-exit {
    opacity: 0;
    transform: translateX(-150px);
}

/* Step 2: shrink */
.table-animated tbody>tr.fade-exit-done>td {
    border: none;
}

.table-animated tr:not(.current) .cell-wrapper {
    max-height: 250px;
    transition-property: max-height, padding;
}

.table-animated tr.fade-exit .cell-wrapper {
    max-height: 0;
    overflow: hidden;
    padding: 0;
}

/* /Fade out */

/* Fade in */
/* animation is fade out reversed, hence max-height -> opacity, transform */

.table-animated tr.fade-enter {
    opacity: 0;
    transform: translateX(-150px);
    transition-delay: .1s, .1s; /* for opacity and transform */
}

.table-animated tr.fade-enter .cell-wrapper {
    transition-delay: 0s;
}

.table-animated tr.fade-enter:not(.fade-enter-active) .cell-wrapper {
    padding: 0;
}

.table-animated tr.fade-enter-active,
.table-animated tr.fade-enter-done {
    opacity: 1;
    transform: translateX(0px);
}

.table-animated tr.fade-enter .cell-wrapper {
    max-height: 0;
    overflow: hidden;
}

.table-animated tr.fade-enter.fade-enter-active .cell-wrapper,
.table-animated tr.fade-enter-done .cell-wrapper {
    max-height: 250px;
}

.table-animated tr.fade-enter.fade-enter-active.current .cell-wrapper {
    max-height: 2000px;
}

.table-pagination {
    line-height: 34px;
}

.table-pagination > span:first-child {
    display: inline-block;
    margin-right: 10px;
    margin-top: 20px;
    vertical-align: top;
}

.table-sticky-head {
    border-collapse: separate;
}

.table-sticky-head th,
.table-sticky-head th:last-child {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: -0.5px;    /* chrome renders 0 with a tiny gap so that text is visible behind  */
    z-index: 3;
}

.table-sticky-head tr:first-child td {
    border-top: none;
}

.table-sticky-head.table-bordered tbody > tr > td,
.table-sticky-head.table-bordered thead > tr > th {
    border-width: 0 1px 1px 0;
}

.table-sticky-head.table-bordered tbody > tr > td:last-child,
.table-sticky-head.table-bordered thead > tr > th:last-child {
    border-right: 0;
}

.table-sticky-head.table-bordered tbody > tr:last-child > td,
.table-sticky-head.table-bordered tbody > tr:nth-last-child(2) > td:nth-child(1),
.table-sticky-head.table-bordered tbody > tr:nth-last-child(2) > td:nth-child(2) {
    border-bottom: 0;
}

.table-solid-bg > tbody > tr {
    background-color: #f9f9f9;
}

.row-anchor {
    position: absolute;
    top: -38px;
}
