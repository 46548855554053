.claim-articles .or {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 21px;
    font-weight: bold;
    text-align: center;
}

.claim-articles .upload-container {
    width: 100%;
}

.claim-articles textarea {
    margin-bottom: 10px;
    min-height: 200px;
    width: 100%;
}

.claim-articles label {
    font-weight: normal;
}

.claim-articles #results {
    margin-top: 10px;
}

.claim-articles #results label {
    display: inline;
}

.claim-articles input {
    margin-right: 5px;
}

.claim-articles .submit-plain-text {
    width: 105px;
    margin-bottom: 10px;
}

.claim-articles .submit-claim {
    margin-bottom: 10px;
}

.claim-articles li {
    line-height: 24px;
}

.claim-articles h3 button {
    font-size: 14px;
    font-weight: normal;
}

.claim-articles .suggested {
    color: #999;
}

#not-found-popover {
    max-width: 90%;
}

.claim-articles .available-author-clusters {
    min-height: calc(100vh - 86px);
}

.claim-articles .available-author-clusters input {
    margin-bottom: 10px;
    margin-top: 5px;
}

.claim-articles #results .btn-primary {
    margin-top: 20px;
}

.claim-articles .articles-list label {
    display: inline;
}

.claim-articles .articles-list h4 {
    margin-top: 20px;
}

.claim-articles .articles-list .selected {
    background: white;
    overflow: hidden;
    padding-bottom: 10px;
    padding-top: 10px;
    position: sticky;
    top: 0;
}

.claim-articles .articles-list .selected strong {
    line-height: 34px;
    vertical-align: bottom;
}

.claim-articles .articles-list .btn-primary {
    width: 115px;
}

.claim-articles .available-author-clusters .show-more {
    list-style: none;
}

.claim-articles .claimed-author {
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 1em;
}

.claim-articles .claim-articles ul li {
    line-height: 20px;
}

.claim-articles textarea {
    white-space: pre;
}

.claimed-count-alert {
    text-align: center;
}
