.to-the-top {
    bottom: 20px;
    padding: 15px 20px;
    position: fixed;
    right: 20px;
    transform: translateY(7px);
    transition: 0.2s transform ease-out;
    z-index: 10;
}

@media (min-width: 768px) {
    bottom: 30px;
    padding: 30px;
    right: 30px;
}

.to-the-top.in {
    transform: translateY(0);
}
