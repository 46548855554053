.entity .actions {
    margin-top: 20px;
}

.entity .actions .btn,
.entity .actions .dropdown {
    margin-bottom: 5px;
    width: 100%;
}

.entity .actions .dropdown .btn {
    margin-bottom: 0;
}

.article .notable {
    background-color: #FF7E26;
    cursor: help;
    vertical-align: middle;
}

.article #search .glyphicon {
    color: #888;
    margin-right: 5px;
    vertical-align: text-top;
}

.article h3 small {
    margin-left: 27px;
}

.article .breadcrumb {
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .article .breadcrumb {
        margin-right: -15px;
    }
}

.author-affiliations .tooltip-inner {
    max-width: 300px;
    text-align: left;
}

.author-affiliations ul {
    padding-left: 20px;
}

.authors .trigger-modal {
    cursor: pointer;
}

#citedPapers .sort-by, #citingPapers .sort-by {
    margin-top: 20px;
}

#citingPapers .citations-count {
    cursor: help;
    border: 1px dotted #777;
}
