.omni-label {
    display: table;
    font-weight: 400;
}

.omni-text {
    margin-left: 5px;
    vertical-align: middle;
}

.omni,
.omni + .rest {
    border: 1px solid #ccc;
}

.omnibox .omni + .rest {
    border-bottom-left-radius: 0;
    border-left: none;
    border-top-left-radius: 0;
}

.omnibox .rest + .omnibox-submit-btn {
    border-bottom-right-radius: 0;
    border-right: none;
    border-top-right-radius: 0;
}

.omnibox {
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    display: flex;
}

.omnibox .rest {
    border-radius: 0;
    border: 1px solid #ccc;
    flex-grow: 1;
}

.omnibox .rest:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.omnibox .rest:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.omnibox .omni + .omnibox {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.omni {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 1px solid #ccc;
    color: #979797;
    padding: 5px 10px 5px 10px;
    pointer-events: none;
    user-select: none;
    white-space: nowrap;
}

.omnibox input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: none;
}

.omni-submit-btn {
    width: auto;
}

.omni-submit-btn .btn {
    height: 100%;
}

.omnibox .dropdown {
    position: absolute;
}
