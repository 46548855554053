.panel-member-candidates-table thead th:first-child {
    border-left: 5px solid transparent;
}

.panel-member-candidates-table input {
    cursor: pointer;
}
.panel-member-row td {
    line-height: 15px !important;
}

.panel-member-row + tr td {
    display: none;
}
.panel-member-row.expanded + tr td {
    border-top: none;
    display: table-cell;
}

.panel-member-row + tr .list-label {
    display: none;
}
