.referee-finder-page #page-container {
    margin-left: 0px;
    margin-right: 10px;
    width: 100%;
}

.year-limit-filter input.form-control {
    width: 5em;
}

.year-limit-filter label + label {
    padding-left: 0;
}

.filter-checkbox {
    cursor: pointer;
    font-weight: normal;
    margin-top: 5px;
}

.filter-checkbox input {
    margin-right: 3px;
}

.hide-coauthors .badge {
    cursor: pointer;
    margin-left: 5px;
}

.hide-coauthors .badge::after {
    content: 'x';
    margin-left: 7px;
}

.cluster-tumbler {
    padding-right: 16px;
    vertical-align: top;
}

.cluster-tumbler input {
    margin-top: 12px;
}

.cluster-progressbar {
    height: 100%;
    padding: 0 16px 20px 0;
}

.cluster-breadcrumbs {
    width: 90%;
}

.note {
    margin-bottom: 10px;
}

.table-double-striped > tbody > tr:nth-of-type(4n+1):not(.custom-author-row):not(.selected-author-row),
.table-double-striped > tbody > tr:nth-of-type(4n+2):not(.custom-author-row):not(.selected-author-row) {
    background-color: #f9f9f9;
}

@media (max-width: 1249px) {
    .ref-popover-container > .referee-popover {
        min-width: 318px;
        max-width: 400px;
    }
}
@media (min-width: 1250px) {
    .ref-popover-container > .referee-popover {
        min-width: 400px;
        max-width: 500px;
    }
}
@media (min-width: 1630px) {
    .ref-popover-container > .referee-popover {
        min-width: 500px;
        max-width: 600px;
    }
}
@media (min-width: 1900px) {
    .ref-popover-container > .referee-popover {
        min-width: 700px;
        max-width: 700px;
    }
}

.table-referees > thead th {
    white-space: nowrap;
}

.table-referees th:first-child,
.table-referees tr td:first-child {
    padding-left: 16px;
}

.table-referees .inline-link:hover::after {
    visibility: visible;
}

.referee-name-header {
    min-width: 150px;
}

.table-referees .add-to-compare {
    min-width: 162px;
}

.table-referees .gender-buttons,
.job-applicants-table .gender-buttons {
    display: none;
    position: absolute;
    white-space: nowrap;
    margin-left: 2px;
    margin-top: -1px;
}

.table-referees .gender-buttons .gender-button,
.job-applicants-table .gender-buttons .gender-button {
    margin: 1px;
    padding: 2px;
    line-height: 1em;
    font-size: 10px;
}

.gender-indicator {
    text-align: center;
    font-family: monospace;
    cursor: pointer;
}

.gender-indicator:hover {
    text-decoration: underline;
}

.table-double-striped > tbody > tr.selected-author-row {
    background-color: #a2cbe0;
}

.table-referee-coauthors.table {
    margin-bottom: 0;
}

.table-referee-coauthors.table td,
.table-referee-coauthors.table th {
    padding-bottom: 0;
}

.referee-details {
    margin-left: 20px;
}

.referee-details > div {
    margin-bottom: 15px;
}

.ref-popover-container {
    float: right;
    position: relative;
    margin-right: -9px;
}

.ref-popover-container > .referee-popover {
    top: -50px;
}

.ref-popover-container > .referee-popover.right > .arrow {
    top: 60px;
}

.clusters-list {
    list-style: none;
    padding: 0;
}

.clusters-list > li {
    display: table;
}

.clusters-list li > div {
    display: table-cell;
}

.clusters-list .toggle {
    display: block;
    margin-bottom: 30px;
    text-align: center;
}

.custom-author-brief span {
    margin-right: 5px;
    padding-right: 5px;
    border-right: 1px solid #ccc;
}

.custom-author-brief span:last-child {
    border-right: none;
}

.referee-chart {
    padding-left: 0.5em;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
}

.referee-chart svg {
    position: absolute;
    top: 8px;
}

.referee-chart-with-legend dl {
    position: relative;
    left: 37px;
    overflow: hidden;
    top: 10px;

}


.referee-chart path {
    fill: none;
}

.referee-chart .author:hover,
.referee-chart .author.highlight {
    stroke: red !important;
    opacity: 1;
    stroke-width: 0.8%;
    filter: drop-shadow( .4px .4px .4px #000 );
}

.referee-chart .author {
    fill: none;
    stroke-width: 0.4%;
}

.referee-chart .author.candidate {
    stroke: #999;
}

.referee-chart .author.custom {
    stroke: #5bc0de;
    stroke-width: 0.8%;
}

.referee-chart .author.marked {
    stroke: #5cb85c;
    stroke-width: 0.8%;
}

.referee-chart .author.selected {
    stroke: blue;
    stroke-width: 0.8%;
}

.referee-chart a text {
    font-size: 3px;
    display: none;
}

.referee-chart a:hover text {
    display: block;
}

.referee-chart .axis,
.referee-chart .axis-ticks path {
    stroke: #333;
    stroke-width: 0.3%;
}

.referee-chart .grid path {
    stroke: #ddd;
    stroke-width: 0.25%;
}

.referee-chart .axis-ticks path {
    stroke-width: 0.3%;
}

.referee-chart .axis-ticks text {
    font: 3.5px sans-serif;
}

.referee-chart .axis-ticks.y text {
    transform: translateY(1.2px);
    text-anchor: end;
}

.referee-chart .axis-ticks.x text {
    text-anchor: middle;
}

.referee-chart .axis-desc {
    font-size: 3px;
}

.referee-chart .axis-desc.y {
    transform: rotate(-90deg);
    transform-origin: center;
}

.referee-chart-with-legend {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    border-right: 0;
}

.referee-chart-with-legend dt,
.referee-chart-with-legend dd {
    font-size: 80%;
    cursor: pointer;
    float: left;
    text-transform: capitalize;
}

.referee-chart-with-legend dt {
    font-size: 0;
    height: 16px;
    margin-left: 13px;
    margin-right: 5px;
    margin-top: 0px;
    width: 16px;
}

.referee-chart-with-legend dt.legend-marked {
    background-color: #5cb85c;
}

.referee-chart-with-legend dt.legend-candidates {
    background-color: #999;
}

.referee-chart-with-legend dt.legend-custom {
    background-color: #5bc0de;
}

.referee-chart-with-legend dt.legend-selected {
    background-color: blue;
}

.referee-chart-with-legend dt:first-child {
    margin-left: 0;
}

.referee-chart-with-legend.hide-marked dt.legend-marked,
.referee-chart-with-legend.hide-candidates dt.legend-candidates,
.referee-chart-with-legend.hide-custom dt.legend-custom,
.referee-chart-with-legend.hide-selected dt.legend-selected {
    opacity: 0.2;
}

.referee-chart-with-legend.hide-marked dd.legend-marked,
.referee-chart-with-legend.hide-candidates dd.legend-candidates,
.referee-chart-with-legend.hide-custom dd.legend-custom,
.referee-chart-with-legend.hide-selected dd.legend-selected {
    opacity: 0.6;
}

.loaded-item-topic-scores {
    margin-bottom: 6px;
}

.referee-popover {
    z-index: 1030;
}

.referee-popover h3 a {
    float: right;
}

.referee-popover h4 {
    margin-top: 20px;
}

.referee-popover h4:first-child {
    margin-top: 10px;
}

.article-popover {
    min-width: 600px;
}
.open-access-icon {
    height: 1em;
    margin-left: 5px;
}

.referees-settings-form.disabled {
    color: #666;
    cursor: wait;
}
.referees-settings-form.disabled input,
.referees-settings-form.disabled .mixed-input,
.referees-settings-form.disabled .btn {
    pointer-events:none;
    color:#AAA;
    background:#F5F5F5;
}

.referees-settings-form .radio-inline {
    margin-right: 5px;
}

.referees-settings-form .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
    font-weight: normal;
}

.referees-lists {
    margin-top: 20px;
}

.referees-lists .main-content {
    padding-right: 1.5em;
}

.referees-lists .main-content.stretched {
    padding-right: 3em;
}

.referees-lists .sidebar {
    z-index: 4;
}

.referees-lists .panel-title > a {
    display: block;
}

.referees-lists .search-authors {
    margin-bottom: 30px;
}

.referees-lists .settings .btn-toolbar {
    display: inline-block;
    height: 34px;
    line-height: 34px;
    vertical-align: middle;
}

.referees-lists .settings .btn-toolbar + .btn {
    margin-left: 5px;
}

.referees-lists .settings .editable {
    display: none;
}

.settings .country-help {
    color: #999;
    font-size: 12px;
    margin-left: 5px;
    margin-right: 5px;
}

.settings .country-item {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
}

.settings .country-count {
    font-style: italic;
    font-size: 12px;
    margin-left: 5px;
}

.referees-lists .settings [type="number"] {
    width: 160px;
}

.referees-lists .settings .country-filter .dropdown {
    margin-bottom: 10px;
}

.referees-lists .number-filter label {
    font-weight: 400;
    min-width: 150px;
    text-align: right;
}

.referees-lists .number-filter input {
    width: 50px !important;
    padding: 3px;
    height: 1.7em;
}

.referees-lists .range-filter span {
    padding: 0 5px;
}

.referees-lists .range-filter label {
    font-weight: 400;
    min-width: 150px;
    text-align: center;
}

.referees-lists .range-filter input {
    width: 70px !important;
    padding: 3px;
    height: 1.7em;
    text-align: center;
}

@media (min-width: 768px) {
    .referees-lists .settings .suggest-input {
        width: 250px;
    }
}

.referees-lists .settings .suggest-box + .btn-toolbar {
    margin-left: 10px;
}

.referees-lists .settings .loading {
    margin-left: 5px;
}

.referees-lists .mass-actions .glyphicon {
    vertical-align: text-top;
}

.mass-actions-wrapper {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 4;
}

.referees {
    margin-bottom: 80px;
}

.table-referees {
    background-color: white;
}

.table-referees th.sortable-cell {
    padding-right: 8px;
}

.table-referees .sortable-cell .order {
    right: 3px;
}

.table-referees .custom-author-row {
    background: #daeaf3;
}

.table-referees .marked-as-referee-row {
    background: #dff0d8 !important;
}

.table-referees tbody tr:nth-child(2n) td {
    padding-top: 0;
    border-top: none;
}

.table-referees .referee-articles button {
    margin-left: 12px;
}

.referee-name-cell {
    position: relative;
}

.referee-row-anchor {
    position: absolute;
    top: -80px;
}

.table-referees .referee-name-cell label {
    display: block;
    clear: both;
    font-weight: normal;
}

.table-referees .referee-name-cell .referee-icons,
.job-applicants-table .referee-icons {
    float: right;
    margin-left: 6px;
    cursor: initial;
}

.table-referees .referee-concepts .list-label {
    display: none;
}

.table-referees .referee-concepts .concept-list {
    margin-left: -6px;
}

.referee-icons-singleline .glyphicon {
    margin: auto 3px;
}

.referee-icons-singleline > div {
    float: left;
}

.referee-icons-singleline .referee-icons-title {
    display: none;
}

.table-referees .actions button,
.table-referees .actions .btn-group {
    margin-right: 3px;
}

.table-referees .actions .glyphicon,
.table-referees .actions .compare-icon {
    vertical-align: text-top;
}

.table-referees .actions .compare-icon {
    width: 14px;
}

.table-referees .actions .dropdown-toggle {
    min-width: auto;
}

.table-referees th:last-child,
.table-referees tr:nth-child(2n+1) td:last-child,
.table-referees.condensed tr:nth-child(2n) td {
    display: none;
}

.table-referees.condensed th:last-child,
.table-referees.condensed tr:nth-child(2n+1) td:last-child,
.table-referees.condensed tr:nth-child(2n).expanded td {
    display: table-cell;
}

.table-referees tr.referee-row-short-info {
    cursor: pointer;
}

.table-referees tr.referee-row-short-info:hover .show-more-referee-info-btn span {
    text-decoration: underline dotted;
}

.table-referees tr.referee-row-short-info td>span {
    cursor: text;
}

.referees svg {
    vertical-align: bottom;
    width: 1em;
}

.add-preliminary-applicants {
    margin-bottom: 1em;
}

.add-preliminary-applicants button {
    margin-right: 0.5em;
}

.ag-badge {
    display: inline-block;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ag-badge-color-0 {
    background-color: #1f77b4;
}

.ag-badge-color-1 {
    background-color: #aec7e8;
}

.ag-badge-color-2 {
    background-color: #ffbb78;
}

.ag-badge-color-3 {
    background-color: #2ca02c;
}

.ag-badge-color-4 {
    background-color: #98df8a;
}

.ag-badge-color-5 {
    background-color: #d62728;
}

.ag-badge-color-6 {
    background-color: #ff9896;
}

.ag-badge-color-7 {
    background-color: #9467bd;
}

.ag-badge-color-8 {
    background-color: #9467bd;
}

.ag-badge-color-9 {
    background-color: #8c564b;
}

.ag-badge-color-10 {
    background-color: #c49c94;
}

.toggle-graph {
    cursor: pointer;
    padding: 0.5em;
    border-radius: 0 4px 4px 0;
}

.toggle-graph .glyphicon {
    margin: 0.5em 0;
}

.hide-graph {
    position: absolute;
    left: -33px;
    top: 120px;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    z-index: 5;
}

.show-graph {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 6;
}

.sidebar-content {
    position: relative;
}
