.two-step-list {
    display: inline-block;
}

.two-step-list .toggle {
    padding-left: 6px;
    padding-right: 6px;
}

.two-step-list .toggle.active {
    background-color: rgba(51, 122, 183, .2);
    border-radius: 3px;
}
