#root > div > .container {
    min-height: calc(100vh - 237px);
}

.page-footer {
    background: #f8f8f8;
    border-top: 1px solid #e7e7e7;
    color: #777;
    padding: 15px 0;
    margin-top: 50px;
}

.page-footer ul {
    margin-bottom: 0;
    margin-top: 15px;
    padding: 0;
}

.page-footer li {
    list-style: none;
    margin-right: 15px;
}

.alert.alert-fixed-bottom-right {
    position: fixed;
    bottom: 20px;
    right: 20px;
    transition: .3s ease-out;
    transition-property: transform, opacity;
    z-index: 4;
}

.alert.alert-fixed-bottom-right.in {
    transform: translateY(5px);
}

.mass-actions .btn {
    margin-right: 5px;
}

.mass-actions .btn:last-child {
    margin-right: 0;
}

input[type="checkbox"].partial {
    opacity: .5;
}

h2.hero-heading::after {
    content: '';
    display: block;
    width: 33%;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: inherit;
}

@media (max-width: 768px) {
    h2.hero-heading::after {
        width: 50%;
    }
}

.inline-link,
.toggle,
.expand {
    background: none;
    border: none;
    color: #337ab7;
    cursor: pointer;
    display: inline;
    padding: 0;
    text-align: left;
}

.inline-link:hover,
.toggle:hover,
.expand:hover {
    background: none;
    color: #23527c;
    text-decoration: underline;
    -webkit-text-decoration: underline;
}

.inline-link:focus,
.toggle:focus,
.expand:focus {
    outline: 0;
}

.form-group.required label::after,
.label-required::after {
    content: '*';
    color: #a94442;
    margin-left: 3px;
}

.panel-table-container {
    border-style: none;
    box-shadow: none;
}

.panel-table-container .panel-heading {
    background-color: #999;
    color: #fff;
}

.panel-table-container .panel-body {
    padding: 0;
}

.panel-table-container .sortable-table + .fetch-more {
    float: right;
    margin-top: -10px;
}

li > .inline-link {
    vertical-align: top;
}

.scroll-spy {
    margin-top: 50px;
    padding: 10px 20px;
    bottom: 30px;
    font-size: 16px;
}

.scroll-spy h5 {
    font-size: 18px;
}

.scroll-spy ul {
    padding-left: 15px;
    list-style: none;
}

.scroll-spy .current {
    font-weight: bold;
    position: relative;
}

.scroll-spy .current a:before {
    content: '';
    width: 2px;
    display: block;
    height: 20px;
    border-left: 2px solid #ff7f0e;
    position: absolute;
    left: -10px;
}

.form-footer {
    overflow: hidden;
}

.form-footer .btn-toolbar {
    float: right;
}

.input-wrapper {
    position: relative;
}

.input-wrapper .glyphicon-refresh {
    position: absolute;
    top: 10px;
    right: 10px;
}

.glyphicon-refresh.spinner {
    -webkit-animation: spinner 1.5s infinite linear;
    animation: spinner 1.5s infinite linear;
    color: rgba(0, 0, 0, .5);
}

.toggle-visibility {
    display: block;
    position: relative;
    text-align: center;
    width: 100%;
}

.toggle-visibility span {
    background: white;
}

.toggle-visibility::before {
    border-top: 1px solid #337ab7;
    content: '';
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: -1;
}

.btn-with-error-row-inline .help-block {
    display: inline;
    margin-left: 10px;
}

.btn-with-error-row {
    overflow: hidden;
}

.btn-with-error-row button,
.btn-with-error-row .help-block {
    float: left;
}

.btn-with-error-row .help-block {
    margin-left: 10px;
}

/* Backport of new bootstrap outline buttons */
.btn-outline {
    background-color: transparent;
    color: inherit;
    transition: all .5s;
}

.btn-primary.btn-outline {
    color: #428bca;
}

.btn-success.btn-outline {
    color: #5cb85c;
}

.btn-info.btn-outline {
    color: #5bc0de;
}

.btn-warning.btn-outline {
    color: #f0ad4e;
}

.btn-danger.btn-outline {
    color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

.alert-outline {
    background-color: transparent;
    color: inherit;
    transition: all .5s;
}

.alert-primary.alert-outline {
    color: #428bca;
}

.alert-success.alert-outline {
    color: #5cb85c;
}

.alert-info.alert-outline {
    color: #5bc0de;
}

.alert-warning.alert-outline {
    color: #f0ad4e;
}

.alert-danger.alert-outline {
    color: #a94442;
}
/* Backport end */

.full-width {
    width: 100%;
}

.overlay-trigger {
    text-decoration: underline dotted;
}

.landing {
    font-size: 16px;
}

@media (min-width: 1600px) {
    .landing {
        font-size: 18px;
    }
}

.landing h2 {
    font-size: 34px;
    margin-bottom: 15px;
}

@media (min-width: 1600px) {
    .landing h2 {
        font-size: 36px;
    }
}

.landing section {
    align-items: center;
    display: flex;
    min-height: 350px;
    overflow: hidden;
    position: relative;
}

@media (max-width: 767px) {
    .landing section {
        flex-direction: column;
    }
}

.landing .hero {
    text-align: center;
}

.landing .hero {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 55%;
}

.landing .section-screenshot .hero {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 15px;
    position: relative;
    width: 100%;
}

@media (min-width: 1024px) {
    .landing .section-screenshot .hero {
        padding: 0;
        position: absolute;
        width: 50%;
    }
}

.landing .section-screenshot .content {
    width: 100%;
}

@media (min-width: 1024px) {
    .landing .section-screenshot .content {
        width: 45%;
    }
}

.landing .section-screenshot img {
    border-radius: 4px;
    box-shadow: 1px 1px 6px #b0cee6;
    max-height: 550px;
    max-width: 100%;
}

@media (min-width: 768px) {
    .landing .section-screenshot img {
        max-width: 90%;
    }
}

.landing .framed .hero {
    clip-path: url(#clip-trapezoid);
}

@media (max-width: 767px) {
    .landing .framed .hero {
        clip-path: none;
    }

    .landing .hero {
        height: 300px;
        position: relative;
        width: 100%;
    }
}

.landing section.net::before {
    background-image: url(./images/home/network.jpg);
    background-repeat: no-repeat;
    background-size: 80%;
    content: '';
    display: block;
    height: 100%;
    margin-top: 15%;
    opacity: 0.12;
    position: absolute;
    transform: rotateZ(16deg);
    transform-origin: left top;
    width: 100%;
}

.landing section.net-right::before {
    transform: rotateZ(-19deg);
    margin-left: 27%;
    margin-top: 40%;
}

@media (max-width: 767px) {
    .landing section .container {
        width: 100%;
    }
}

.landing section .content {
    height: 100%;
    padding: 50px;
    position: relative;
    width: 45%;
    z-index: 1;
}

@media (max-width: 767px) {
    .landing section .content {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .landing section .hero + .container .content {
        left: 50%;
    }
}

@media (max-width: 1023px) {
    .landing .section-screenshot .hero + .container .content {
        left: 0;
    }
}

.landing section.framed .hero + .container .content {
    left: 55%;
}

@media (max-width: 767px) {
    .landing section.framed .hero + .container .content {
        left: 0;
    }
}

@media (min-width: 768px) {
    .landing section .container:only-child .content {
        margin: 0 auto;
        width: 65%;
    }
}

.landing section.blue,
.landing section.blue.framed::after {
    background-color: #F6FBFF;
}

.landing section .hero:first-child {
    right: auto;
    left: 0;
}

.landing section.framed .hero {
    background-repeat: no-repeat;
    background-size: cover;
}

.landing section:not(.framed) .content {
    padding: 50px 10px;
}

@media (max-width: 1023px) {
    .landing .section-screenshot {
        flex-direction: column;
    }
}

@media (max-width: 1023px) {
    .landing .section-screenshot.mobile-reverse {
        flex-direction: column-reverse;
    }
}

.full-width #root > div > .container {
    padding: 0;
    width: auto;
}

.landing .jumbotron img,
.landing .content img {
    display: block;
    margin: 30px auto;
}

.landing .jumbotron img {
    border: 8px solid #333;
    border-radius: 8px;
    width: 100%;
}

.landing .blue .content .screenshot {
    border-radius: 4px;
    box-shadow: 1px 1px 6px #b0cee6;
    width: 100%;
}

.landing li {
    margin-bottom: 10px;
}

.landing dd {
    margin-bottom: 10px;
}

.landing .btn {
    margin-top: 30px;
    font-size: 20px;
}

.landing .buttons {
    margin-top: 30px;
}

.landing .buttons .btn {
    margin-top: 0;
}

.landing .buttons .or {
    margin: 0 10px;
}

.landing section.framed.content-right .hero {
    clip-path: url(#clip-trapezoid-right);
}

.get-in-touch h2::before {
    background-image: url(./images/home/icons/phone-book.svg);
}


@media (max-width: 767px) {
    .landing section.framed.content-right .hero {
        clip-path: none;
    }
}

.landing .q-and-a dt::before {
    content: 'Q: ';
}

.landing .q-and-a dd::before {
    content: 'A: ';
}

.dropdown .btn.active,
.dropdown .btn:focus {
    outline: 0;
}

.inplace-dropdown {
    display: inline;
}
.inplace-dropdown .inplace-label {
    font-weight: normal;
    vertical-align: middle;
}
.inplace-dropdown .dropdown-select {
    height: auto;
    border: 0;
    box-shadow: none;
    color: #23527c;
    font-weight: bold;
    display: inline-block;
    width: fit-content;
    padding: 0;
    margin: 0;
    vertical-align: middle;
    cursor: pointer;
}

.panel-table-container .panel-title .inline-link {
    color: inherit;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    margin-left: 5px;
}

.panel-table-container .panel-title .inline-link.active {
    color: #555;
}

.panel-table-container .panel-title .inline-link:hover {
    color: #666;    /* bwa-ha-ha */
    text-decoration: none;
}

.panel-table-container .settings {
    border: 1px solid #ccc;
    border-top: none;
    max-height: 0;
    overflow: hidden;
}

.panel-table-container .settings-toggle {
    font-size: 10pt;
    line-height: 14pt;
    color: #fff !important;
}

.panel-table-container .updating .glyphicon-cog {
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.panel-table-container .settings-toggle:hover {
    color: #fff !important;
}

.panel-table-container .glyphicon-cog {
    transition: transform .3s linear;
    transform: rotate(0);
}

.panel-table-container.show-settings .settings {
    max-height: none;
    overflow: visible;
}

.panel-table-container.show-settings .glyphicon-cog {
    transform: rotate(90deg);
}

.panel-table-container .settings .wrapper {
    padding: 15px;
}

.btn .loading {
    margin-top: -1px;
}

.panel-sm .panel-heading,
.panel-sm .panel-body {
    font-size: 12px;
    line-height: 20px;
}

.panel-sm .panel-heading {
    padding: 4px 10px;
}

.panel-sm .panel-body {
    padding: 10px;
}

.panel-paddingless .panel-body {
    padding: 0;
}

small.bigger {
    font-size: 75%;
}

.article-link .badge {
    background-color: #FF7E26;
    vertical-align: baseline;
}
